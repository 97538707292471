// workspaceSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getAllContacts, getWorkspace } from "../Thunks/workspaceAction";

const initialState = {
	isLoading: false,
	tags: [],
	taxes: [],
	owners: [],
	categories: [],
	currencies: [],
	globalCurrencies: [],
	companyCustomFields: [],
	customFields: [],
	userWorkspaceRelation: {},
	userPermissions: {
		instagram: {},
		proposals: {},
		customers: {},
		invoices: {},
		items: {},
	},
	userCompany: {},
	company: {},
	workspace: {},
	dashboardData: {},
	workspaces: [],
	currentWorkspace: {},
	allContacts: [],
};

export const workspaceSlice = createSlice({
	name: "workspace",
	initialState,
	reducers: {
		setDashboardStats: (state, action) => {
			state.dashboardData = action.payload;
		},
		setOwners: (state, action) => {
			state.owners = action.payload;
		},
		setTaxes: (state, action) => {
			state.taxes = action.payload;
		},
		addTax: (state, action) => {
			state.taxes.push(action.payload);
		},
		setGlobalCurrencies: (state, action) => {
			state.globalCurrencies = action.payload;
		},
		addCurrencies: (state, action) => {
			state.currencies.push(action.payload);
		},
		setCurrencies: (state, action) => {
			state.currencies = action.payload;
		},
		deleteCurrency: (state, action) => {
			state.currencies.splice(action.payload, 1);
		},
		setCategories: (state, action) => {
			state.categories = action.payload;
		},
		addCategory: (state, action) => {
			state.categories.push(action.payload);
		},
		setTags: (state, action) => {
			state.tags = action.payload;
		},
		addTags: (state, action) => {
			state.tags.push(action.payload);
		},
		addWorkspace: (state, action) => {
			state.workspaces.push(action.payload);
		},
		setWorkspaces: (state, action) => {
			state.workspaces = action.payload;
		},
		setCurrentWorkspace: (state, action) => {
			state.currentWorkspace = action.payload;
		},
		deleteWorkspace: (state, action) => {
			const deleteIndex = state.workspaces.findIndex(
				(workspace) => workspace.id === action.payload
			);
			state.workspaces.splice(deleteIndex, 1);
			state.currentWorkspace = {};
		},
		setUserPermissions: (state, action) => {
			state.userCompany = action.payload.company_info;
			state.userPermissions = {};
			action.payload.permissions.forEach((permission) => {
				state.userPermissions[permission.name] = true;
			});
			state.userWorkspaceRelation = { ...action.payload };
			delete state.userWorkspaceRelation.permissions;
			delete state.userWorkspaceRelation.company_info;
		},
		setWorkspace: (state, action) => {
			state.workspace = action.payload;
			if (action.payload.id === state.currentWorkspace.id) {
				state.currentWorkspace = { ...action.payload };
			}
		},
		setCompany: (state, action) => {
			state.company = action.payload;
			state.userCompany = action.payload;
		},
		setCompanyCustomFields: (state, action) => {
			state.companyCustomFields = action.payload;
		},
		changeLogo: (state, action) => {
			state.workspace.media = action.payload;
		},
		deleteLogo: (state) => {
			state.workspace.media = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllContacts.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getAllContacts.fulfilled, (state, action) => {
				state.isLoading = false;
				state.allContacts = action.payload;
			});

		builder
			.addCase(getWorkspace.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getWorkspace.fulfilled, (state, action) => {
				state.isLoading = false;
				state.workspace = action.payload;
			})
			.addCase(getWorkspace.rejected, (state, action) => {
				state.isLoading = false;
			});
	},
});

export const {
	setDashboardStats,
	setOwners,
	setTaxes,
	addTax,
	setGlobalCurrencies,
	addCurrencies,
	setCurrencies,
	deleteCurrency,
	setCategories,
	addCategory,
	setTags,
	addTags,
	addWorkspace,
	setWorkspaces,
	setCurrentWorkspace,
	deleteWorkspace,
	setUserPermissions,
	setWorkspace,
	setCompany,
	setCompanyCustomFields,
	changeLogo,
	deleteLogo,
} = workspaceSlice.actions;

export const workspaceReducer = workspaceSlice.reducer;
