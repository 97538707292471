import { redirect } from "next/navigation";
import axios from "axios";
import _ from "lodash";
import Api from "@/http/axiosInterceptor";

const { createAsyncThunk } = require("@reduxjs/toolkit");

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const onBoarding = createAsyncThunk(
  "dashboard/onBoarding",
  async (arg, { getState, rejectWithValue }) => {
    const data = {
      onboarding: arg,
    };
    try {
      const response = await Api.post(`${API_URL}/api/auth/onboarding`, data);
      if (!response?.data) return rejectWithValue("On boarding unsuccessful !");
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resendVerification = createAsyncThunk(
  "dashboard/resendVerification",
  async (arg, { getState, rejectWithValue }) => {
    const { userInfo } = getState().auth;
    const data = {
      user: userInfo,
    };
    try {
      const response = await Api.post(
        `${API_URL}/api/auth/resend-verify-code`,
        data
      );

      if (!response?.data)
        return rejectWithValue("Failed to send verification mail !");
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPageBuilderAnalytics = createAsyncThunk(
  "dashboard/getPageBuilderAnalytics",
  async (arg, { rejectWithValue }) => {
    const params = {
      startDate: arg && arg.start ? arg.start : "",
      endDate: arg && arg.end ? arg.end : "",
    };
    try {
      const response = await Api.get(`${API_URL}/api/dashboard/pagebuilder`, {
        params,
      });

      if (!response?.data) {
        return rejectWithValue("pageBuilder analytics could not be found");
      }
      // const temp = {
      //   total_click: 899,
      //   number_of_projects: 56,
      //   location_info: [
      //     {
      //       country: "India",
      //       total: 886,
      //     },
      //     {
      //       country: "Singapore",
      //       total: 3,
      //     },
      //     {
      //       country: "Sweden",
      //       total: 2,
      //     },
      //     {
      //       country: "United States",
      //       total: 8,
      //     },
      //   ],
      //   device_info: [
      //     {
      //       device: "Desktop",
      //       total: 389,
      //     },
      //     {
      //       device: "Mobile",
      //       total: 510,
      //     },
      //   ],
      //   referrer_info: [
      //     {
      //       referrer: "app.linkjoy.io",
      //       total: 130,
      //     },
      //     {
      //       referrer: "com.google.android.googlequicksearchbox",
      //       total: 17,
      //     },
      //     {
      //       referrer: "Email, SMS and More",
      //       total: 747,
      //     },
      //     {
      //       referrer: "l.facebook.com",
      //       total: 3,
      //     },
      //     {
      //       referrer: "lnkj.in",
      //       total: 1,
      //     },
      //     {
      //       referrer: "m.facebook.com",
      //       total: 1,
      //     },
      //   ],
      //   top_browser: "Chrome",
      // };

      return response.data.data;
      //return temp; //TODO : this is the temporary information we are sending
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUrlShortenerAnalytics = createAsyncThunk(
  "dashboard/getUrlShortenerAnalytics",
  async (arg, { rejectWithValue }) => {
    const params = {
      startDate: arg && arg.start ? arg.start : "",
      endDate: arg && arg.end ? arg.end : "",
    };
    try {
      const response = await Api.get(`${API_URL}/api/dashboard/url-shortener`, {
        params,
      });
      if (!response?.data) {
        return rejectWithValue("urlShortner analytics could not be found");
      }
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
