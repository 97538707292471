import { usePathname } from "next/navigation";
import { useMemo } from "react";

const uuidRegex = /[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/;

function useServerRouter() {
	const path = usePathname();

	const checkWorkspace = (string) => uuidRegex.test(string);

	const route = useMemo(() => {
		const pathParts = path.split("/");
		if (checkWorkspace(pathParts[1])) {
			pathParts[1] = "workspace";
		}
		pathParts.shift();

		return pathParts.join("-");
	}, [path]);

	return { name: route, currentPath: path };
}

export default useServerRouter;
