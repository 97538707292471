"use client";
import useServerRouter from "@/hooks/serverRouter";
import usePreviousPath from "@/hooks/usePreviousPath";
import { useAppDispatch, useRouter, useAppSelector } from "@/lib/hooks";
import { syncPersistence } from "@/redux/Slice/authSlice";
import Cookies from "js-cookie";
import _ from "lodash";
import { DateTime } from "luxon";
import { useEffect } from "react";

function ProtectedRoute({ children }) {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const previousPath = usePreviousPath();

  const { currentPath, name } = useServerRouter();

  const { workspaceId } = useAppSelector((state) => state.portal);
  useEffect(() => {
    if (window != undefined) {
      const token = Cookies.get("auth.local.token");
      const workspace = JSON.parse(localStorage.getItem("workspace"));
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const strategy = Cookies.get("auth.strategy");

      if (name.includes("/ltd-checkout")) {
        if (token && workspace && workspace?.subscription.workspace_id) {
          return router.push(`/${workspace?.uuid}/settings/ltd-plan-buy`);
        } else {
          return router.push("/auth/sign-in");
        }
      } else if (
        _.includes(currentPath, "settings") &&
        !_.includes(previousPath, "settings") &&
        !_.includes(previousPath, "ltd-checkout")
      ) {
        dispatch(syncPersistence({ adminPath: previousPath }));
      } else if (
        _.includes(currentPath, "settings") &&
        !_.includes(previousPath, "ltd-checkout")
      ) {
        dispatch(
          syncPersistence({
            adminPath: "/" + workspace?.uuid + "/dashboard",
          })
        );
      }

      let portalOnboardingLinks = ["clientportal-sign-in"];

      const authOutLinks = [
        "auth-sign-in",
        "auth-sign-up",
        "auth-reset-password",
        "auth-set-password",
        "auth-access-account",
        "auth-invite",
        "auth-welcome-mobile",
      ];

      // This is for Client Portal

      if (_.includes(authOutLinks, name)) {
        return;
      }

      if (strategy === "portal") {
        if (userInfo) {
          // if (
          // 	DateTime.utc().diff(
          // 		userInfo.workspace?.owner.expired_time,
          // 		"minutes"
          // 	) > 0 ||
          // 	DateTime.utc().diff(
          // 		userInfo.workspace.subscription.plan_end_date,
          // 		"minutes"
          // 	) > 0
          // ) {
          // 	if (name !== "workspace-expired") {
          // 		router.replace(
          // 			`/${userInfo.workspace?.uuid}/expired`
          // 		);
          // 	}
          // }
          if (
            _.includes(authOutLinks, name) &&
            name !== "workspace-expired" &&
            (_.includes(name, "clientportal") ||
              name === "clientportal-sign-in")
          ) {
            router.replace("/clientportal/linkinbio");
          }
        } else if (
          currentPath !== "/clientportal/sign-in" &&
          _.includes(authOutLinks, name)
        ) {
          if (!_.includes(name, "clientportal")) {
            // const workspace_id=currentPath.replace(
            // 					"/clientportal/",
            // 					""
            // 				)
            router.replace(
              `/clientportal/sign-in?workspacce_id=${workspaceId}`
            );
          } else {
            return router.replace("/auth/sign-in");
          }
        }
      } else {
        if (_.includes(portalOnboardingLinks, name)) {
          return;
        } else {
          if (userInfo) {
            if (currentPath === `/clientportal/${workspace?.uuid}`) {
              if (strategy === "local") {
                router.replace(`/clientportal/linkinbio?setup=template`);
              }
            }
            // if (workspace?.subscription?.plan_end_date !== null) {
            //   const date1 = new Date();
            //   const date2 = new Date(workspace?.subscription.plan_end_date);
            //   const diffTime = date2 - date1 <= 0 ? 0 : date2 - date1;
            //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            //   const unrestrictedPathNames = [
            //     "workspace-settings",
            //     "workspace-settings-billing",
            //   ];

            //   if (workspace && workspace?.uuid && diffDays <= 0) {
            //     if (_.includes(unrestrictedPathNames, name)) {
            //       return true;
            //     } else {
            //       return router.replace(`/${workspace?.uuid}/settings/billing`);
            //     }
            //   }
            // }
            if (_.includes(authOutLinks, currentPath)) {
              router.replace(`/${workspace?.uuid}/dashboard`);
            }
          } else {
            if (currentPath === "/") {
              router.replace("/auth/sign-in");
            } else {
              if (
                _.includes(currentPath, "/clientportal") ||
                _.includes(currentPath, "null")
              ) {
                const workspace_id = currentPath.replace("/clientportal/", "");
                router.replace(
                  `/clientportal/sign-in?workspace_id=${workspace_id}`
                );
              }
            }
          }
        }
      }
    }
  }, [router]);
  return children;
}

export default ProtectedRoute;
