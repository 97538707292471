import { createSlice, current } from "@reduxjs/toolkit";
import {
  getUrlShortenerData,
  getUrlSlug,
  searchUrl,
  getQRCode,
  getWorkspaceContacts,
  getCategories,
  changeTag,
  postUTMValues,
  getUTMDefaultValues,
  bulkEdit,
  getUTMParams,
  getOGValues,
  getGDPRValues,
  getABRotatorValues,
  getGeoLocation,
  getDeepLinkpps,
  getDeepLinkAppEvents,
  getAllContacts,
} from "@/redux/Thunks/admin/adminUrlRetargetingAction";
import { removeLink } from "@/redux/Thunks/urlRetargetingPortalAction";

const initialState = {
  urlShortenerListing: [],
  urlSlug: "",
  qrData: [],
  categories: [],
  workspaceContacts: [],
  isLoading: false,
  getUTMParamsLoading: false,
  getGdprValuesLoading: false,
  getABRotatorisLoading: false,
  error: null,
  utmSourceList: [],
  utmMediumList: [],
  utmCampaignList: [],
  utmDefaultValue: {},
  utmParams: {},
  gdprValues: {},
  abRotatorValues: [],
  geoLocationValues: [
    {
      redirection_link: "",
      country_code: "",
    },
  ],
  geoLoading: false,
  geoError: null,
  ogValues: {},
  ogLoading: false,
  ogError: null,
  getDLLoading: false,
  deepLinkingApps: [],
  dlLoading: false,
  dlError: null,
  deepLinkEvents: {},
  dlEventsLoading: false,
  dlEventsError: null,
  allContacts: [],
  allLoading: false,
  allError: null,
  removeLinkApi: {
    status: "idle",
    error: null,
  },
};
const adminUrlRetargeting = createSlice({
  name: "adminUrlRetargeting",
  initialState,

  reducers: {
    updateAbStore(state, action) {
      state.abRotatorValues = action.payload;
    },

    updateGeoLocation(state, action) {
      state.geoLocationValues = action.payload;
    },
    updateGeoLocationValue(state, action) {
      const { index, field, value } = action.payload;

      if (state.geoLocationValues[index]) {
        state.geoLocationValues[index][field] = value;
      }
    },

    updateAbLocationValue(state, action) {
      const { index, value } = action.payload;

      if (state.abRotatorValues[index]) {
        state.abRotatorValues[index]["redirection_link"] = value;
      }
    },
  },

  extraReducers(builder) {
    //--------------------------------------------------------------------------------------------------------
    // builder.addCase(getUrlSlug.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getUrlSlug.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.urlSlug = action.payload;
    // });
    // builder.addCase(getUrlSlug.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.error;
    // });
    //--------------------------------------------------------------------------------------------------------
    builder.addCase(bulkEdit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bulkEdit.fulfilled, (state, action) => {
      state.isLoading = false;
      const { response } = action.payload?.data;

      // Update each project in the projects array if its id matches with any changedUser id
    });
    builder.addCase(bulkEdit.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    //--------------------------------------------------------------------------------------------------------

    builder.addCase(getOGValues.pending, (state, action) => {
      state.ogLoading = true;
    });
    builder.addCase(getOGValues.fulfilled, (state, action) => {
      state.ogValues = action.payload;
      state.ogLoading = false;
    });
    builder.addCase(getOGValues.rejected, (state, action) => {
      state.ogError = action.error;
      state.ogLoading = false;
    });

    //---------------------------------------------------------------------------------------------------------------------------

    builder.addCase(searchUrl.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(searchUrl.fulfilled, (state, action) => {
      state.isLoading = false;
      state.urlShortenerListing = action.payload.data.data;
    });
    builder.addCase(searchUrl.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    //-------------------------------------------------Modal--------------------------------------------------
    builder.addCase(getQRCode.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getQRCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.qrData = action.payload.data;
    });
    builder.addCase(getQRCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //---------------------------------------------------------------------------------------------------------
    builder.addCase(getGeoLocation.pending, (state, action) => {
      state.geoLoading = true;
    });

    builder.addCase(getGeoLocation.fulfilled, (state, action) => {
      state.geoLocationValues = action.payload.data;

      state.geoLoading = false;
    });

    builder.addCase(getGeoLocation.rejected, (state, action) => {
      state.geoLoading = false;
      state.geoError = action.error;
    });

    //---------------------------------------------------------------------------------------------------------
    builder.addCase(getUTMDefaultValues.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUTMDefaultValues.fulfilled, (state, action) => {
      state.isLoading = false;
      state.utmDefaultValue = action.payload.data;
    });
    builder.addCase(getUTMDefaultValues.rejected, (state, action) => {
      state.isLoading = true;
      state.error = action.error;
    });
    //------------------------------------------------------------------------------------------------------

    builder.addCase(getUTMParams.pending, (state) => {
      state.getUTMParamsLoading = true;
    });
    builder.addCase(getUTMParams.fulfilled, (state, action) => {
      state.getUTMParamsLoading = false;
      state.utmParams = action.payload;
    });
    builder.addCase(getUTMParams.rejected, (state, action) => {
      state.getUTMParamsLoading = false;
      state.error = action.error;
    });

    //------------------------------------------------------------------------------------------------------
    builder.addCase(getGDPRValues.pending, (state) => {
      state.getGdprValuesLoading = true;
    });
    builder.addCase(getGDPRValues.fulfilled, (state, action) => {
      state.getGdprValuesLoading = false;
      state.gdprValues = action.payload.data.data;
    });
    builder.addCase(getGDPRValues.rejected, (state, action) => {
      state.getGdprValuesLoading = false;
      state.error = action.error;
    });

    //------------------------------------------------------------------------------------------------------

    builder.addCase(getABRotatorValues.pending, (state) => {
      state.getABRotatorisLoading = true;
    });
    builder.addCase(getABRotatorValues.fulfilled, (state, action) => {
      state.getABRotatorisLoading = false;

      state.abRotatorValues = action.payload;
    });
    builder.addCase(getABRotatorValues.rejected, (state, action) => {
      state.getABRotatorisLoading = false;
      state.error = action.error;
    });
    // ------------------------------------------------------------------------------------------------------ Deeptis
    builder.addCase(getAllContacts.pending, (state, action) => {
      state.allLoading = true;
    });
    builder.addCase(getAllContacts.fulfilled, (state, action) => {
      state.allContacts = action.payload.data.data;
      state.allLoading = false;
    });
    builder.addCase(getAllContacts.rejected, (state, action) => {
      state.allError = action.error;
      state.allLoading = false;
    });
    //----------------------------------------------------------------------------------------------------
    builder.addCase(getDeepLinkAppEvents.pending, (state, action) => {
      state.dlEventsLoading = true;
    });
    builder.addCase(getDeepLinkAppEvents.fulfilled, (state, action) => {
      state.deepLinkEvents = action.payload;
      state.dlLoading = false;
    });
    builder.addCase(getDeepLinkAppEvents.rejected, (state, action) => {
      state.dlEventsError = action.error;
      state.dlLoading = false;
    });
    //---------------------------------------------------------------------------------------------------
    builder.addCase(getDeepLinkpps.pending, (state) => {
      state.dlLoading = true;
    });
    builder.addCase(getDeepLinkpps.fulfilled, (state, action) => {
      state.deepLinkingApps = action.payload.data;
      state.dlLoading = false;
    });
    builder.addCase(getDeepLinkpps.rejected, (state, action) => {
      state.dlError = action.error;
      state.dlLoading = false;
    });
    //--------------------------------------------------------------------------------------------------------
    builder.addCase(getUrlSlug.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUrlSlug.fulfilled, (state, action) => {
      state.isLoading = false;
      state.urlSlug = action.payload;
    });
    builder.addCase(getUrlSlug.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(removeLink.pending, (state, action) => {
      if (state.removeLinkApi.status === "idle") {
        state.removeLinkApi.status = "pending";
      }
    });
    builder.addCase(removeLink.fulfilled, (state, action) => {
      if (state.removeLinkApi.status === "pending") {
        state.removeLinkApi.status = "idle";
      }
    });
    builder.addCase(removeLink.rejected, (state, action) => {
      if (state.removeLinkApi.status === "pending") {
        state.removeLinkApi.error = action.payload;
        state.removeLinkApi.status = "idle";
      }
    });
    //--------------------------------------------------------------------------------------------------------
    // builder.addCase(bulkEdit.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(bulkEdit.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   const { response, contact } = action.payload?.data;

    //   // Update each project in the projects array if its id matches with any changedUser id
    //   state.urlShortenerListing.projects.forEach((project) => {
    //     response.data.forEach((changedUser) => {
    //       if (project.id === changedUser.id) {
    //         project.receiver_contact_id = contact.id;
    //       }
    //     });
    //   });
    // });
  },
});

export const {
  updateAbStore,
  updateGeoLocation,
  updateGeoLocationValue,
  updateAbLocationValue,
} = adminUrlRetargeting.actions;

export const adminUrlRetargetingReducer = adminUrlRetargeting.reducer;
