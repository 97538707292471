import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@/http/axiosInterceptor";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getUrlShortenerDataById = createAsyncThunk(
  "clientUrlRetargeting/getUrlShortenerDataById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/project/${id}`
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
