import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    linkinBio: [],
    portalLinkInBio: [],
    instagramPosts: [],
    nextUrl: "",
    analyticsInfo: {},
    pagination: {
    current_page: 1
    }
}

const linkinBioSlice = createSlice({
    name: 'linkinBio',
    initialState,
    reducers: {
        resetState(state){
            return initialState;
        },
        
        setLinkinBio(state, action){
            state.linkinBio = action.payload;
        },

        setPortalLinkinBio(state,action){
            state.portalLinkInBio = action.payload;
        },

        addLinkinBio(state, action){
            state.linkinBio.push(action.payload);
        },

        addInstagramPosts(state, action){
            state.instagramPosts = action.payload;
        },

        updateInstagramPosts(state, action){
            state.instagramPosts.push(...action.payload);
        },

        addNextUrl(state, action){
            state.nextUrl = action.payload;
        },

        deleteLinkinBio(state, action){
            state.linkinBio.splice(action.payload, 1);
        },

        editLinkinBio(state, action) {
            const { index, data } = action.payload;
            state.linkinBio[index] = data;
        },

        editInstagramPostLinkinBio(state, action) {
            const { index, data } = action.payload;
            state.instagramPosts[index] = data;
        },

        deleteLinkinPosts(state, action) {
            state.instagramPosts.splice(action.payload, 1);
        },

        setAnalyticsInfo(state, action) {
            state.analyticsInfo = action.payload;
        },

        setLinkinBioPagination(state, action) {
            state.pagination = action.payload
        }
    }
});
  
export const {
    resetState,
    setLinkinBio,
    setPortalLinkinBio,
    addLinkinBio,
    addInstagramPosts,
    updateInstagramPosts,
    addNextUrl,
    deleteLinkinBio,
    editLinkinBio,
    editInstagramPostLinkinBio,
    deleteLinkinPosts,
    setAnalyticsInfo,
    setLinkinBioPagination

} = linkinBioSlice.actions;

export const linkinBioReducer = linkinBioSlice.reducer;