// hooks/usePreviousPath.js
import { useEffect, useState } from "react";

const usePreviousPath = () => {
	const [prevPath, setPrevPath] = useState(null);

	useEffect(() => {
		const previousPath = document.referrer || null;
		setPrevPath(previousPath);
	}, []);

	return prevPath;
};

export default usePreviousPath;
