import { createSlice, current } from "@reduxjs/toolkit";
import {
  getTrackingPixels,
  postTrackingPixels,
  updateTrackingPixels,
  deleteTrackingPixels,
  trakingTools,
  addTracking,
  editTracking,
  deleteTracking,
  getUrlShortenerTrackingTools,
  postUrlShortenerTrackingTools,
  updateUrlShortenerTrackingTools,
  deleteUrlShortenerTrackingTools,
} from "@/redux/Thunks/trackingAction";

const initialState = {
  showKanban: true,
  searchTerm: "",
  view: "kanban",
  trackings: [],
  trackingPixels: [],
  gtpIsLoading: false,
  gtpError: null,
  ptpIsLoading: false,
  ptpError: null,
  utpIsLoading: false,
  utpError: null,
  dtpIsLoading: false,
  dtpIsError: null,
  ttIsLoading: false,
  ttError: null,
  atIsLoading: false,
  atError: null,
  etIsLoading: false,
  etError: null,
  createUrlShortenerTrackingPixelsApi: {
    status: "idle",
    error: null,
  },
  getUrlShortenerTrackingPixelsApi: {
    status: "idle",
    error: null,
  },
  updateUrlShortenerTrackingPixelApi: {
    status: "idle",
    error: null,
  },
  deleteUrlShortenerTrackingPixelApi: {
    status: "idle",
    error: null,
  },
  updateTrackApi: {
    status: "idle",
    error: null,
  },
  createUrlShortenerGlobalTrackingPixelsApi: {
    status: "idle",
    error: null,
  },
  getUrlShortenerGlobalTrackingPixelsApi: {
    status: "idle",
    error: null,
  },
  updateUrlShortenerGlobalTrackingPixelApi: {
    status: "idle",
    error: null,
  },
  deleteUrlShortenerGlobalTrackingPixelApi: {
    status: "idle",
    error: null,
  },
  updateTrackApi: {
    status: "idle",
    error: null,
  },
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState: initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setTracking(state, action) {
      if (action.payload !== null) {
        state.trackings = action.payload;
      }
    },
    setTrackingPixels(state, action) {
      if (action.payload !== null) {
        state.trackingPixels = action.payload;
      }
    },
    addTracking(state, action) {
      if (action.payload !== null) {
        state.trackings.push(action.payload);
      }
    },
    addTrackingPixels(state, action) {
      if (action.payload !== null) {
        state.trackingPixels.push(action.payload);
      }
    },
    deleteTracking(state, action) {
      state.trackings = state.trackings.filter(
        (elem) => elem.id !== action.payload
      );
    },

    editTracking(state, action) {
      const index = state.trackings.findIndex(
        (elem) => elem.id === action.payload.tracking_id
      );
      if (index !== -1) {
        state.trackings[index] = action.payload.responseData;
      }
    },
    editTrackingPixels(state, action) {
      const index = state.trackingPixels.findIndex(
        (elem) => elem.id === action.payload.tracking_id
      );
      if (index !== -1) {
        state.trackingPixels[index] = action.payload.responseData;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackingPixels.pending, (state, action) => {
      state.gtpIsLoading = true;
    });
    builder.addCase(getTrackingPixels.fulfilled, (state, action) => {
      state.trackingPixels = action.payload;
      state.gtpIsLoading = false;
    });
    builder.addCase(getTrackingPixels.rejected, (state, action) => {
      state.gtpIsLoading = false;
      state.gtpError = action.error;
    });

    builder.addCase(getUrlShortenerTrackingTools.pending, (state, action) => {
      state.ptpIsLoading = true;
    });
    builder.addCase(getUrlShortenerTrackingTools.fulfilled, (state, action) => {
      state.trackings = action.payload.data.data;
      state.ptpIsLoading = false;
    });
    builder.addCase(getUrlShortenerTrackingTools.rejected, (state, action) => {
      state.ptpIsLoading = false;
      state.ptpError = action.error;
    });

    builder.addCase(updateTrackingPixels.pending, (state, action) => {
      state.utpIsLoading = true;
    });
    builder.addCase(updateTrackingPixels.fulfilled, (state, action) => {
      // const index = state.trackingPixels.findIndex(
      //   (elem) => elem.id === action.payload.id
      // );
      // if (index !== -1) {
      //   state.trackingPixels[index] = action.payload.data;
      // }
      // state.utpIsLoading = false;
      if (state.updateUrlShortenerTrackingPixelApi.status === "idle") {
        state.updateUrlShortenerTrackingPixelApi.status = "pending";
        state.updateUrlShortenerTrackingPixelApi.error = null;
      }
    });
    builder.addCase(updateTrackingPixels.rejected, (state, action) => {
      state.utpIsLoading = false;
      state.utpError = action.error;
    });

    builder.addCase(deleteTrackingPixels.pending, (state, action) => {
      state.dtpIsLoading = true;
    });
    builder.addCase(deleteTrackingPixels.fulfilled, (state, action) => {});
    builder.addCase(deleteTrackingPixels.rejected, (state, action) => {
      state.dtpIsLoading = false;
      state.dtpIsError = action.error;
    });

    builder.addCase(trakingTools.pending, (state, action) => {
      state.ttIsLoading = true;
    });
    builder.addCase(trakingTools.fulfilled, (state, action) => {
      state.trackings = action.payload;
      state.ttIsLoading = false;
    });
    builder.addCase(trakingTools.rejected, (state, action) => {
      state.ttIsLoading = false;
      state.ttError = action.error;
    });

    // builder.addCase(addTracking.pending, (state, action) => {
    //   state.atIsLoading = true;
    // });
    // builder.addCase(addTracking.fulfilled, (state, action) => {
    //   state.trackings.push(action.payload);
    //   state.atIsLoading = False;
    // });
    // builder.addCase(addTracking.rejected, (state, action) => {
    //   state.atIsLoading = s
    //   state.atError = action.error;
    // });

    builder.addCase(editTracking.pending, (state, action) => {
      state.etIsLoading = true;
    });
    builder.addCase(editTracking.fulfilled, (state, action) => {
      const index = state.trackings.findIndex(
        (elem) => elem.id === action.payload.tracking_id
      );
      if (index !== -1) {
        state.trackings[index] = action.payload.data;
      }
      state.etIsLoading = false;
    });

    builder.addCase(editTracking.rejected, (state, action) => {
      state.etIsLoading = false;
      state.etError = action.error;
    });

    //   builder.addCase(deleteTracking.fulfilled, (state, action) => {
    //     state.trackings = state.trackings.filter(
    //       (elem) => elem.id !== action.payload
    //     );
    //   })
    //   builder.addCase(getUrlShortenerTrackingTools.fulfilled, (state, action) => {
    //     state.trackings = action.payload;
    //   })
    //   builder.addCase(postUrlShortenerTrackingTools.fulfilled, (state, action) => {
    //     state.trackings.push(action.payload);
    //   })
    builder.addCase(
      updateUrlShortenerTrackingTools.pending,
      (state, action) => {
        if (state.updateTrackApi.status === "idle") {
          state.updateTrackApi.status === "pending";
        }
      }
    );
    builder.addCase(
      updateUrlShortenerTrackingTools.fulfilled,
      (state, action) => {
        if (state.updateTrackApi.status === "pending") {
          const index = state.trackings.findIndex(
            (elem) => elem.id === action.payload.tracking_id
          );
          if (index !== -1) {
            state.trackings[index] = action.payload.data;
          }
          state.updateTrackApi.status === "idle";
        }
      }
    );
    builder.addCase(
      updateUrlShortenerTrackingTools.rejected,
      (state, action) => {
        if (state.updateTrackApi.status === "pending") {
          state.updateTrackApi.status === "idle";
          state.updateTrackApi.error = action.error;
        }
      }
    );
    builder.addCase(
      deleteUrlShortenerTrackingTools.fulfilled,
      (state, action) => {
        state.trackings = state.trackings.filter(
          (elem) => elem.id !== action.payload
        );
      }
    );
    builder.addCase(postUrlShortenerTrackingTools.pending, (state, action) => {
      if (state.createUrlShortenerTrackingPixelsApi.status === "idle") {
        state.createUrlShortenerTrackingPixelsApi.status = "pending";
        state.createUrlShortenerTrackingPixelsApi.error = null;
      }
    });
    builder.addCase(
      postUrlShortenerTrackingTools.fulfilled,
      (state, action) => {
        if (state.createUrlShortenerTrackingPixelsApi.status === "pending") {
          state.createUrlShortenerTrackingPixelsApi.status = "idle";
        }
      }
    );
    builder.addCase(postUrlShortenerTrackingTools.rejected, (state, action) => {
      if (state.createUrlShortenerTrackingPixelsApi.status === "pending") {
        state.createUrlShortenerTrackingPixelsApi.status = "idle";
        state.createUrlShortenerTrackingPixelsApi.error = action.error;
      }
    });
  },
});

export const trackingSliceReducer = trackingSlice.reducer;
