import Api from "@/http/axiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  setUser,
  setContactUuid,
  setCurrentWorkspace,
} from "../Slice/portalSlice";
const API_URL = process.env.NEXT_PUBLIC_BASE_URL;
import Cookies from "js-cookie";

export const getUserInfo = createAsyncThunk(
  "portal/getUserInfo",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.get(`${API_URL}/api/clientportal/user`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const signIn = createAsyncThunk(
  "portal/signIn",
  async (arg, { getState, rejectWithValue }) => {
    const { authUser: User, recaptchaToken } = getState().auth;
    let workspaceId;
    if (arg !== null) {
      workspaceId = arg;
    }
    localStorage.setItem("auth.strategy", "portal");
    Cookies.set("auth.strategy", "portal");

    const strategy = Cookies.get("auth.strategy");
    const data = {
      user: {
        email: User.email,
        password: User.password,
      },
      workspace_id: workspaceId,
    };
    try {
      const response = await Api.post(
        `${API_URL}/api/auth/clientPortal/login`,
        data
      );
      const userData = { ...response.data };
      Cookies.set("auth.portal.token", userData.response.data.token);
      Cookies.set(
        "workspace",
        JSON.stringify(userData.response.data.user.workspace)
      );

      localStorage.setItem("auth.portal.token", userData.response.data.token);
      localStorage.setItem(
        "userInfo",
        JSON.stringify(userData.response.data.user)
      );
      //TODO : Jaimin - Ask the backend that thi object will be 'User'  and not 'data'
      return userData.response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const signOut = createAsyncThunk(
  "portal/signOut",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      localStorage.clear();

      Cookies.remove("auth.portal.token");
      Cookies.remove("auth.strategy");
      Cookies.remove("workspace");
      //Cookies.remove("Workspace");
      Cookies.remove("ContactUUID");
      dispatch(setUser({}));
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "portal/changePassword",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        "/api/auth/clientportal/change-password",
        { user: userData }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const setPortalData = createAsyncThunk(
  "portal/setPortalData",
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `/api/clientportal/workspace-detail/${uuid}`
      );
      setCurrentWorkspace(response.data.data);
      Cookies.set("workspace", JSON.stringify(response.data.data));
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const setPublicPortalData = createAsyncThunk(
  "portal/setPublicPortalData",
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `/api/clientportal/workspace-publicDetail/${uuid}`
      );
      setCurrentWorkspace(response.data.data);

      //Cookies.set("workspace", JSON.stringify(response.data.data));
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClientsList = createAsyncThunk(
  "portal/getClientsList",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      data = _.isUndefined(data) ? { page: 1, searchTerm: "" } : data;
      const response = await Api.get(
        `${API_URL}/api/clientportal/clients?search=` + data.searchTerm
      );
      //return dummyClient; // TODO : REMOVE THIS IN FUTURE

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const getClientsList = createAsyncThunk(
//   "portal/getClientsList",
//   async (params) => {
//     try {
//       const response = await AP.get("/api/clientportal/clients");
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const changeClient = createAsyncThunk(
  "portal/changeClient",
  async (client, { rejectWithValue, dispatch, getState }) => {
    try {
      const strategy = Cookies.get("auth.strategy");
      let contactUuid;

      contactUuid = client;

      // dispatch(reset());

      Cookies.set("ContactUUID", contactUuid);
      Api.defaults.headers.common["CUUID"] = contactUuid;
      if (contactUuid !== "null" && contactUuid !== "undefined") {
        dispatch(setContactUuid(contactUuid));
        await dispatch(getUserInfo());
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postLogo = createAsyncThunk(
  "portal/postLogo",
  async ({ workspace_id, file, ownerId }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("workspace_id", workspace_id);
      formData.append("type", "company_logo");
      formData.append("file", file);
      const response = await Api.post(
        `${API_URL}/api/media/upload/user/${ownerId}`,
        formData
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteLogo = createAsyncThunk(
  "portal/deleteLogo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/media/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
