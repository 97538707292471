import Api from "@/http/axiosInterceptor";
import { useAppSelector } from "@/lib/hooks";
import axios from "axios";
import Cookies from "js-cookie";
import _ from "lodash";

const { createAsyncThunk } = require("@reduxjs/toolkit");

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const validateUserData = createAsyncThunk(
  "auth/validateUserData",
  async (arg, { getState, rejectWithValue }) => {
    let { authUser, recaptchaToken } = getState().auth; // Access the Redux state using getState
    const data = {
      user: {
        ...authUser,
        recaptchaToken: recaptchaToken,
      },
    };

    const url = `${API_URL}/api/auth/validate-user-data`;
    try {
      const response = await axios.post(url, data);
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createSession = createAsyncThunk(
  "auth/createSession",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const url = `${API_URL}/api/create-session`;
      const response = await axios.post(url, arg);

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (arg, { getState, rejectWithValue }) => {
    const {
      authUser: User,
      recaptchaToken,
      currency,
      stripeCustomerId,
      selectedCountry,
      testClockId,
    } = getState().auth;

    const data = {
      user: {
        first_name: User.first_name,
        last_name: User.last_name,
        email: User.email,
        password: User.password,
        agreement: User.agreement,
        news_updates: User.news_updates,
        refer_by: User.refer_by,
        recaptchaToken: recaptchaToken,
        track_id: User.track_id,
        type: User.type,
        plan_slug: currency.slug,
        payment_intent_id: arg,
        customer_id: stripeCustomerId,
        selected_country: selectedCountry,
        testClockId: testClockId,
      },
    };

    try {
      const response = await axios.post(`${API_URL}/api/auth/register`, data);

      const userData = { ...response.data };

      if (typeof window !== "undefined") {
        // Cookies.set("accessToken", userData.data.token);

        Cookies.set("auth.strategy", "local");
        localStorage.setItem("auth.strategy", "local");
        Cookies.set("auth.local.token", userData.data.token);
        delete userData.data.token;
        localStorage.setItem("userInfo", JSON.stringify(userData.data)); //TODO : Ask the backend that thi object will be 'User'  and not 'data'
      }
      return userData;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const appSumoRegisterUser = createAsyncThunk(
  "auth/appSumoRegisterUser",
  async ({ formData }, { rejectWithValue }) => {
    const data = {
      user: {
        ...formData,
      },
    };

    try {
      const response = await axios.post(`${API_URL}/api/auth/register`, data);

      const userData = { ...response.data };

      if (typeof window !== "undefined") {
        // Cookies.set("accessToken", userData.data.token);
        Cookies.set("auth.strategy", "local");
        localStorage.setItem("auth.strategy", "local");
        Cookies.set("auth.local.token", userData.data.token);
        delete userData.data.token;
        localStorage.setItem("userInfo", JSON.stringify(userData.data)); //TODO : Ask the backend that thi object will be 'User'  and not 'data'
      }
      return userData;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllUserDetails = createAsyncThunk(
  "auth/getAllUserDetails",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("auth.local.token");
    if (!token) {
      return (window.location.href = `/auth/sign-in`);
    }
    try {
      const response = await axios.get(`${API_URL}/api/auth/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userDetails = { ...response.data.data };

      if (userDetails.name === undefined) {
        userDetails.name =
          userDetails.first_name +
          (userDetails.last_name !== null ? " " : "") +
          (userDetails.last_name !== null ? userDetails.last_name : "");
      }

      if (!_.isEmpty(userDetails.workspace)) {
        localStorage.setItem(
          "workspace",
          JSON.stringify(userDetails.workspace)
        );
      }

      if (!_.isEmpty(userDetails)) {
        localStorage.setItem("userInfo", JSON.stringify(userDetails));
      }

      return userDetails;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (arg, { getState, rejectWithValue }) => {
    const { authUser: User, recaptchaToken } = getState().auth;
    let workspaceId;
    if (arg !== null) {
      workspaceId = arg;
    }
    localStorage.setItem("auth.strategy", "local");
    Cookies.set("auth.strategy", "local");

    const strategy = Cookies.get("auth.strategy");

    const data = {
      user: {
        email: User.email,
        password: User.password,
        recaptchaToken: recaptchaToken,
        type: User.type,
      },
    };

    try {
      const response = await axios.post(`${API_URL}/api/auth/login`, data);

      const userData = { ...response.data };

      delete userData.token;

      localStorage.setItem("userInfo", JSON.stringify(userData.data.user)); //TODO : Jaimin - Ask the backend that thi object will be 'User'  and not 'data'
      localStorage.setItem(
        "workspace",
        JSON.stringify(userData.data.user.workspace)
      );
      localStorage.setItem("userInfo", JSON.stringify(userData.data.user)); //TODO : Jaimin - Ask the backend that thi object will be 'User'  and not 'data'
      //Cookies.set("userInfo", JSON.stringify(userData.data.user));
      Cookies.set("workspace", JSON.stringify(userData.data.user.workspace));

      return userData.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const applyForgotPassword = createAsyncThunk(
  "auth/applyForgotPassword",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/forgot-password`,
        arg
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyToken = createAsyncThunk(
  "auth/verifyToken",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/token-verification`,
        {
          verification: arg,
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(
          error.response.data || "Your token has expired."
        );
      } else {
        return rejectWithValue("Your token has expired.");
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (userData, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/api/auth/reset-password`, {
        verification: userData,
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/auth/logout`);
      localStorage.clear();
      Cookies.remove("auth.local.token");
      Cookies.remove("auth.portal.token");
      Cookies.remove("auth.strategy");
      Cookies.remove("workspace");
      Cookies.remove("Workspace");
      Cookies.remove("ContactUUID");
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (userData, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/verify-email?email=${userData?.email}&token=${userData?.token}`
      );

      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalProjects = createAsyncThunk(
  "auth/getTotalProjects",
  async (userData, { getState, rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/total-projects`);

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalClicks = createAsyncThunk(
  "/get-total-clicks",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/total-clicks`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
