import Api from "@/http/axiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const createClient = createAsyncThunk(
  "clientPortal/createClient",
  async (client, { rejectWithValue }) => {
    try {
      const response = await Api.post(`${API_URL}/api/client`, {
        client,
      });
      return response.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPrimaryClient = createAsyncThunk(
  "clientPortal/getPrimary",
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/client/${uuid}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClientContacts = createAsyncThunk(
  "clientPortal/getClientContacts",
  async ({ clientId, paramsData }, { rejectWithValue }) => {
    paramsData = _.isUndefined(paramsData) ? { page: 1 } : paramsData;
    try {
      const response = await Api.get(
        `${API_URL}/api/contacts/${clientId}?page=${paramsData.page}`
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createContact = createAsyncThunk(
  "clientPortal/createContacts",
  async ({ contact, clientId }, { rejectWithValue }) => {
    try {
      const response = await Api.post(`${API_URL}/api/contact/${clientId}`, {
        contact,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const EditContact = createAsyncThunk(
  "clientPortal/editContact",
  async ({ contact, contactId }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`${API_URL}/api/contact/${contactId}`, {
        contact,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteContact = createAsyncThunk(
  "clientPortal/deleteContact",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`${API_URL}/api/contact/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
