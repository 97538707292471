import { createSlice } from "@reduxjs/toolkit";
import {
  applyForgotPassword,
  createSession,
  getAllUserDetails,
  getTotalClicks,
  getTotalProjects,
  registerUser,
  signIn,
  validateUserData, //TODO : jaimin  - finish this once the backend is implemented
} from "../Thunks/auth/authApi.js";
import constants from "@/lib/utils/constants";

import _ from "lodash";
import {
  deleteProfileImage,
  getUserDetails,
  postChangePassword,
  postProfileImage,
  postUserInfo,
} from "../Thunks/settingsAction.js";
import moment from "moment";

const initialState = {
  isLoading: false,
  errorMsg: null,
  userInfo: null,
  authUser: null,
  selectedCountry: null,
  totalNoOfClick: null,
  testClockId: null,
  currency: constants.MRRPlans[0],
  recaptchaToken: null,
  clientSecret: null,
  stripeCustomerId: null,
  payment_intent_id: null,
  workspace: null,
  authStrategy: "local",
  isPlanExpired: false,
  persistence: {
    admin: {
      sideNavCollapse: false,
    },
    hideDefaultLauncher: true,
    adminPath: "/",
    settingsPath: "/",
    instagram: {
      view: "kanban",
    },
  },
  userProfileData: null,
  getUserDetailsApi: {
    status: "idle",
    error: null,
  },
  postProfileImageApi: {
    status: "idle",
    error: null,
  },
  deleteProfileImageApi: {
    status: "idle",
    error: null,
  },
  postChangePasswordApi: {
    status: "idle",
    error: null,
  },
  postUserInfoApi: {
    status: "idle",
    error: null,
  },
  getTotalClicksApi: {
    status: "idle",
    noOfClicks: null,
    error: null,
  },
  totalProjects: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.authUser = action.payload;
    },
    updateUserData: (state, action) => {
      const newData = { ...state.authUser, ...action.payload };

      state.authUser = newData;
    },

    // updatePlanSLug: (state, action) => {
    // 	state.planSlug = action.payload;
    // },
    updateCurrentCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    updateCurrencySymbol: (state, action) => {
      state.currencySymbol = action.payload;
    },
    setRecaptchaToken: (state, action) => {
      state.recaptchaToken = action.payload;
    },

    updateCurrency: (state, action) => {
      state.currency = action.payload;
    },

    updatePaymentIntentId: (state, action) => {
      state.payment_intent_id = action.payload;
    },

    updateUserVerification: (state, action) => {
      state.isVerifiedUser = true;
    },

    syncPersistence: (state, action) => {
      state.persistence = _.assign({}, state.persistence, action.payload);
    },

    updateStrategy: (state, action) => {
      state.authStrategy = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(validateUserData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(validateUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        // TODO : complete this slice
      })
      .addCase(validateUserData.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createSession.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.isLoading = false;

        state.stripeCustomerId = action.payload.data.stripeCustomerId;
        state.clientSecret = action.payload.data.clientSecret;
        state.testClockId = action.payload.data.testClockId;
      })
      .addCase(createSession.rejected, (state, action) => {
        state.isLoading = false;
        state.stripeCustomerId = null;
        state.clientSecret = null;
      })
      .addCase(registerUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllUserDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload;
        state.workspace = action.payload?.workspace;
      })
      .addCase(getAllUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.error || "User Details not found";
      })
      .addCase(signIn.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload?.user;
        state.workspace = action.payload?.workspace;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(applyForgotPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(applyForgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(applyForgotPassword.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTotalProjects.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTotalProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalProjects = action.payload;
      })
      .addCase(getTotalProjects.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getUserDetails.pending, (state, action) => {
        if (state.getUserDetailsApi.status === "idle") {
          state.getUserDetailsApi.status = "pending";
        }
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        if (state.getUserDetailsApi.status === "pending") {
          state.userProfileData = action.payload.data?.data;
          const data = action.payload.data?.data?.workspace?.subscription;

          const nextBillingDate = data?.next_billing_date;
          const isPlanExpired = Boolean(data?.is_trail)
            ? moment(new Date()).isAfter(moment(data?.next_billing_date))
            : !Boolean(data?.is_trail) && data?.plan_end_date
            ? moment(new Date()).isAfter(moment(data?.plan_end_date))
            : false;

          state.isPlanExpired = isPlanExpired;
          state.getUserDetailsApi.status = "idle";
        }
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        if (state.getUserDetailsApi.status === "pending") {
          state.getUserDetailsApi.status = "idle";
          state.getUserDetailsApi.error = action.error;
        }
      })
      .addCase(postProfileImage.pending, (state, action) => {
        if (state.postProfileImageApi.status === "idle") {
          state.postProfileImageApi.status = "pending";
        }
      })
      .addCase(postProfileImage.fulfilled, (state, action) => {
        if (state.postProfileImageApi.status === "pending") {
          state.postProfileImageApi.status = "idle";
        }
      })
      .addCase(postProfileImage.rejected, (state, action) => {
        if (state.postProfileImageApi.status === "pending") {
          state.postProfileImageApi.status = "idle";
          state.postProfileImageApi.error = action.error;
        }
      })
      .addCase(deleteProfileImage.pending, (state, action) => {
        if (state.deleteProfileImageApi.status === "idle") {
          state.deleteProfileImageApi.status = "pending";
        }
      })
      .addCase(deleteProfileImage.fulfilled, (state, action) => {
        if (state.deleteProfileImageApi.status === "pending") {
          state.deleteProfileImageApi.status = "idle";
        }
      })
      .addCase(deleteProfileImage.rejected, (state, action) => {
        if (state.deleteProfileImageApi.status === "pending") {
          state.deleteProfileImageApi.status = "idle";
          state.deleteProfileImageApi.error = action.error;
        }
      })
      .addCase(postChangePassword.pending, (state, action) => {
        if (state.postChangePasswordApi.status === "idle") {
          state.postChangePasswordApi.status = "pending";
        }
      })
      .addCase(postChangePassword.fulfilled, (state, action) => {
        if (state.postChangePasswordApi.status === "pending") {
          state.postChangePasswordApi.status = "idle";
        }
      })
      .addCase(postChangePassword.rejected, (state, action) => {
        if (state.postChangePasswordApi.status === "pending") {
          state.postChangePasswordApi.status = "idle";
          state.postChangePasswordApi.error = action.error;
        }
      })
      .addCase(postUserInfo.pending, (state, action) => {
        if (state.postUserInfoApi.status === "idle") {
          state.postUserInfoApi.status = "pending";
        }
      })
      .addCase(postUserInfo.fulfilled, (state, action) => {
        if (state.postUserInfoApi.status === "pending") {
          state.postUserInfoApi.status = "idle";
        }
      })
      .addCase(postUserInfo.rejected, (state, action) => {
        if (state.postUserInfoApi.status === "pending") {
          state.postUserInfoApi.status = "idle";
          state.postUserInfoApi.error = action.error;
        }
      })
      .addCase(getTotalClicks.pending, (state, action) => {
        if (state.getTotalClicksApi.status === "idle") {
          state.getTotalClicksApi.status = "pending";
        }
      })
      .addCase(getTotalClicks.fulfilled, (state, action) => {
        if (state.getTotalClicksApi.status === "pending") {
          state.getTotalClicksApi.status = "idle";
          state.getTotalClicksApi.noOfClicks = action.payload.total_clicks;
        }
      })
      .addCase(getTotalClicks.rejected, (state, action) => {
        if (state.getTotalClicksApi.status === "pending") {
          state.getTotalClicksApi.status = "idle";
          state.getTotalClicksApi.error = action.payload || action.error;
        }
      });
  },
});

export const {
  setUserData,
  updateUserData,
  updatePlanSLug,
  updateAmount,
  updateCurrencySymbol,
  updateCurrentCountry,
  setRecaptchaToken,
  updateCurrency,
  updateUserVerification,
  updatePaymentIntentId,
  syncPersistence,
} = authSlice.actions;

export default authSlice.reducer;
