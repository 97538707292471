import { createSlice } from "@reduxjs/toolkit";
import {
  getPageBuilderAnalytics,
  getUrlShortenerAnalytics,
} from "../Thunks/dashboard/dashboardApi";

// Define the initial state
const initialState = {
  isLoading: false,
  dashboardData: [],
  clicks: 0,
  pageBuilderData: [],
  isPbDashboard: false,
  isUrlDashboard: false,
  linkInBioData: [],
  urlShortenerData: [],
};

// Define a slice for the Redux store
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // Reset the state to its initial values
    resetState(state) {
      return initialState;
    },
    // Set the dashboard state
    setDashboardState(state, action) {
      state.dashboardData = action.payload;
    },
    // Set the total clicks remaining state
    setClicksRemainingState(state, action) {
      state.totalClicks = action.payload;
    },
    // Set the page builder data state
    setPageBuilderData(state, action) {
      state.pageBuilderData = action.payload;
    },
    // Set the link in bio data state
    setLinkInBioData(state, action) {
      state.linkInBioData = action.payload;
    },
    // Set the URL shortener data state
    setUrlShortenerData(state, action) {
      state.urlShortenerData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPageBuilderAnalytics.pending, (state, action) => {
        state.isLoading = true;
        state.isPbDashboard = true;
      })
      .addCase(getPageBuilderAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isPbDashboard = false;
        state.pageBuilderData = action.payload;
      })
      .addCase(getPageBuilderAnalytics.rejected, (state, action) => {
        state.isLoading = false;
        state.isPbDashboard = false;
        state.errorMsg = action.error;
      })
      .addCase(getUrlShortenerAnalytics.pending, (state, action) => {
        state.isLoading = true;
        state.isUrlDashboard = true;
      })
      .addCase(getUrlShortenerAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUrlDashboard = false;
        state.urlShortenerData = action.payload;
      })
      .addCase(getUrlShortenerAnalytics.rejected, (state, action) => {
        state.isLoading = false;
        state.isUrlDashboard = false;

        state.errorMsg = action.error;
      });
  },
});

// Extract action creators and reducer from the slice
export const {
  resetState,
  setDashboardState,
  setClicksRemainingState,
  setPageBuilderData,
  setLinkInBioData,
  setUrlShortenerData,
} = dashboardSlice.actions;

// Export the reducer function
export const dashboardReducer = dashboardSlice.reducer;
