import { getUrlShortenerDataById } from "@/redux/Thunks/client/clientUrlRetargetingAction";
import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  urlRetargeting: {},
  isUrlRetargetingLoading: false,
};

const clientUrlRetargeting = createSlice({
  name: "clientUrlRetargeting",
  initialState,

  extraReducers(builder) {
    builder
      .addCase(getUrlShortenerDataById.pending, (state, action) => {
        state.isUrlRetargetingLoading = true;
      })
      .addCase(getUrlShortenerDataById.fulfilled, (state, action) => {
        state.isUrlRetargetingLoading = false;

        state.urlRetargeting = action.payload;
      })
      .addCase(getUrlShortenerDataById.rejected, (state, action) => {
        state.isUrlRetargetingLoading = false;
      });
  },
});

export const clientUrlRetargetingReducer = clientUrlRetargeting.reducer;
