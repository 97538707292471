import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@/http/axiosInterceptor";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getTrackingPixels = createAsyncThunk(
  "tracking/getTrackingPixels",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${API_URL}/api/pixel-default-value/`;

      const response = await Api.get(url);

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postTrackingPixels = createAsyncThunk(
  "tracking/postTrackingPixels",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/pixel-default-value/`,
        data.payload
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTrackingPixels = createAsyncThunk(
  "tracking/updateTrackingPixels",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/pixel-default-value/${data.tracking_id}`,

        data.payload
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTrackingPixels = createAsyncThunk(
  "tracking/deleteTrackingPixels",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(
        `${API_URL}/api/pixel-default-value/${id.trackingId}`
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const trakingTools = createAsyncThunk(
  "tracking/trakingTools",
  async (page_builder_id, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/tracking/pagebuilder/${page_builder_id}`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addTracking = createAsyncThunk(
  "tracking/addTracking",
  async ({ traking_data, page_builder_id }, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/tracking/pagebuilder/${page_builder_id}`,
        {
          tracking_data: traking_data,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editTracking = createAsyncThunk(
  "tracking/editTracking",
  async (
    { traking_data, page_builder_id, tracking_id },
    { rejectWithValue }
  ) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/tracking/${tracking_id}/pagebuilder/${page_builder_id}`,
        {
          tracking_data: traking_data,
        }
      );
      return { data: response.data, tracking_id };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTracking = createAsyncThunk(
  "tracking/deleteTracking",
  async ({ id, page_builder_id }, { rejectWithValue }) => {
    try {
      await Api.delete(
        `${API_URL}/api/tracking/${id}/pagebuilder/${page_builder_id}`
      );
      return id;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// Define thunks for LinkinBio and UrlShortener tracking tools in a similar way...
export const getUrlShortenerTrackingTools = createAsyncThunk(
  "tracking/getUrlShortenerTrackingTools",
  async (urlShortenerId, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/tracking/urlshortener/${urlShortenerId}`
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// API not ready yet:
export const postUrlShortenerTrackingTools = createAsyncThunk(
  "tracking/postUrlShortenerTrackingTools",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/tracking/urlshortener/${data.id}`,

        data.payload
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUrlShortenerTrackingTools = createAsyncThunk(
  "tracking/updateUrlShortenerTrackingTools",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `${API_URL}/api/tracking/${data.tracking_id}/urlshortener/${data?.urlShortenerId}`,
        data.payload
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUrlShortenerTrackingTools = createAsyncThunk(
  "tracking/deleteUrlShortenerTrackingTools",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.delete(
        `${API_URL}/api/tracking/${data.trackingId}/urlshortener/${data.urlShortenerId}`
      );
      return response.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
