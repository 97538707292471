"use client";

import Loader from "nextjs-toploader";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import nProgress from "nprogress";

export const NextTopLoader = () => {
  const pathname = usePathname();

  useEffect(() => {
    nProgress.done();
  }, [pathname]);

  return (
    <Loader
      color="#2299DD"
      initialPosition={0.08}
      crawlSpeed={200}
      height={5}
      crawl={true}
      showSpinner={true}
      easing="ease"
      speed={200}
      shadow="0 0 10px #2299DD,0 0 5px #2299DD"
      shallowRouting
    />
  );
};
