import { toast } from "@/components/ui/use-toast";
import rootReducer from "@/redux";
import { configureStore } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";

const rejectedErrorLogger = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const message = action.payload?.response?.data?.message;
    toast({
      variant: "destructive",
      title:
        message ||
        action.payload?.message ||
        "Something went wrong please try again",
    });
  }
  return next(action);
};

const customMiddleware = (store) => (next) => (action) => {
  // Custom middleware logic

  return next(action);
};

export const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(customMiddleware)
        .concat(rejectedErrorLogger),
  });
};
