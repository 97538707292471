import Api from "@/http/axiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getClients = createAsyncThunk(
  "client/getClients",
  async (data = { page: 1, searchTerm: "" }, { rejectWithValue, dispatch }) => {
    // data = _.isUndefined(data) ? { page: 1, searchTerm: "" } : data;
    const params = new URLSearchParams();
    params.append("page", data?.page || 1);
    params.append("search", data?.searchTerm || "");
    if (data.records_per_page)
      params.append("records_per_page", data.records_per_page);
    if (data.clientId) params.append("client_id", data.clientId);

    try {
      const response = await Api.get(
        `${API_URL}/api/clients?${params.toString()}`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const getClients = createAsyncThunk(
//   "client/getClients",
//   async (data, { rejectWithValue, dispatch }) => {
//     data = _.isUndefined(data) ? { page: 1, searchTerm: "" } : data;
//     try {
//       const response = await Api.get(
//         `${API_URL}/api/clients?page=` +
//           data.page +
//           "&search=" +
//           data.searchTerm +
//           "&records_per_page=" +
//           (data.records_per_page ? data.records_per_page : "")
//       );

//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );

export const searchUrl = createAsyncThunk(
  "client/search",
  async (term, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/clients?page=1` +
          "&search=" +
          `${term}` +
          "&records_per_page="
      );

      return { data: response.data, headers: {} };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteClients = createAsyncThunk(
  "client/deleteClients",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.delete(`${API_URL}/api/client/` + data);
      //return dummyClient; // TODO : REMOVE THIS IN FUTURE
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putClient = createAsyncThunk(
  "client/putClient",
  async (data1, { rejectWithValue, dispatch }) => {
    const client = data1.client;
    try {
      const response = await Api.put(`${API_URL}/api/client/` + data1.id, {
        client,
      });
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
