import { createSlice } from "@reduxjs/toolkit";
import {
  assignCustomDomainToClients,
  deleteCustomDomainFavicon,
  getAllWorkspaceClients,
  getBillingDetails,
  getCustomDomain,
  getCustomDomainForPortal,
  getCustomDomainSettings,
  getSingleCustomDomain,
  postPageNotFoundUrl,
  saveNewCustomDomain,
  assignFreePlan,
  applyCodes,
  cancelSubscription,
  reactivateAccount,
} from "../Thunks/settingsAction";

const initialState = {
  billing: [],
  ltdPlans: [],
  allRecurringPlans: [],
  allAddOnPlans: [],
  showTrailBanner: "",
  customDomain: [],
  clientCustomDomain: [],
  getCustomDomainLoading: false,
  getCustomDomainError: null,
  singleCustomDomain: {},
  isAllowCustomDomain: false,
  ogValues: {},
  payments: [],
  billingLoading: false,
  billingError: null,
  isLoading: false,
  error: null,
  getCustomDomainApi: {
    status: "idle",
    error: null,
  },
  getSingleCustomDomainApi: {
    status: "idle",
    error: null,
  },
  workspaceContacts: [],
  getAllWorkspaceClientsApi: {
    status: "idle",
    error: null,
  },
  deleteCustomDomainFaviconApi: {
    status: "idle",
    error: null,
  },
  postPageNotFoundUrlApi: {
    status: "idle",
    error: null,
  },
  assignCustomDomainToClientsApi: {
    status: "idle",
    error: null,
  },
  saveNewCustomDomainApi: {
    status: "idle",
    error: null,
  },
  assignFreePlanApi: {
    status: "idle",
    error: null,
  },
  applyCodesApi: {
    status: "idle",
    error: null,
  },
  cancelSubscriptionApi: {
    status: "idle",
    error: null,
  },
  reactivateAccountApi: {
    status: "idle",
    error: null,
  },
  planData: {
    noOfDays: null,
    type: null,
    nextBillingDate: null,
    noOfDaysUntilNextBilling: null,
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setBilling(state, action) {
      state.billing = action.payload;
    },
    setLtdPlansData(state, action) {
      state.ltdPlans = action.payload.filter(
        (plan) => plan.slug !== "linkjoy-free"
      );
    },
    setMrrPlansData(state, action) {
      state.allRecurringPlans = action.payload;
    },
    setAddOnPlansData(state, action) {
      state.allAddOnPlans = action.payload;
    },
    setTrailBanner(state, action) {
      state.showTrailBanner = action.payload;
    },
    // setCustomDomain(state, action) {
    // 	state.customDomain = action.payload;
    // },
    setSingleCustomDomain(state, action) {
      state.singleCustomDomain = action.payload;
    },
    // setCustomDomainSettings(state, action) {
    // 	state.isAllowCustomDomain = action.payload;
    // },
    setOgState(state, action) {
      state.ogValues = action.payload;
    },
    setPayments(state, action) {
      state.payments = action.payload;
    },
    addPayment(state, action) {
      state.payments.push(action.payload);
    },
    updatePayment(state, action) {
      const index = state.payments.findIndex(
        (payment) => payment.type === action.payload.type
      );
      if (index !== -1) {
        state.payments[index] = {
          ...state.payments[index],
          ...action.payload,
        };
      }
    },
    resetCustomDomain: (state) => {
      state.customDomain = [];
      state.singleCustomDomain = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSingleCustomDomain.pending, (state, action) => {
        if (state.getSingleCustomDomainApi.status === "idle") {
          state.getSingleCustomDomainApi.status = "pending";
        }
      })
      .addCase(getSingleCustomDomain.fulfilled, (state, action) => {
        if (state.getSingleCustomDomainApi.status === "pending") {
          state.singleCustomDomain = action.payload;
          state.getSingleCustomDomainApi.status = "idle";
        }
      })
      .addCase(getSingleCustomDomain.rejected, (state, action) => {
        if (state.getSingleCustomDomainApi.status === "pending") {
          state.getSingleCustomDomainApi.status = "idle";
          state.getSingleCustomDomainApi.error = action.error;
        }
      })
      .addCase(getBillingDetails.pending, (state, action) => {
        state.billingLoading = true;
      })
      .addCase(getBillingDetails.fulfilled, (state, action) => {
        state.billing = action.payload;
        state.billingLoading = false;
      })
      .addCase(getBillingDetails.rejected, (state, action) => {
        state.billingError = action.error;
        state.billingLoading = false;
      })
      .addCase(getCustomDomainForPortal.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCustomDomainForPortal.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload.map((domain) => ({
          value: domain.id,
          label: domain.domain_name,
        }));

        state.clientCustomDomain = [...data];

        state.customDomain = action.payload;
      })
      .addCase(getCustomDomainForPortal.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getCustomDomainSettings.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCustomDomainSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAllowCustomDomain = action.payload?.is_allow_custom_domain;
        const data = action.payload.map((domain) => ({
          value: domain.id,
          label: domain.domain_name,
        }));

        state.clientCustomDomain = [...data];

        state.customDomain = action.payload;
      })
      .addCase(getCustomDomainSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getCustomDomain.pending, (state, action) => {
        if (state.getCustomDomainApi.status === "idle") {
          state.getCustomDomainApi.status = "pending";
        }
      })
      .addCase(getCustomDomain.fulfilled, (state, action) => {
        if (state.getCustomDomainApi.status === "pending") {
          const data = action.payload.map((domain) => ({
            value: domain.id,
            label: domain.domain_name,
          }));

          state.clientCustomDomain = [...data];

          state.customDomain = action.payload;

          state.getCustomDomainApi.status = "idle";
        }
      })
      .addCase(getCustomDomain.rejected, (state, action) => {
        if (state.getCustomDomainApi.status === "pending") {
          state.getCustomDomainApi.status = "idle";
          state.getCustomDomainApi.error = action.error;
        }
      })
      .addCase(getAllWorkspaceClients.pending, (state, action) => {
        if (state.getAllWorkspaceClientsApi.status === "idle") {
          state.getAllWorkspaceClientsApi.status = "pending";
        }
      })
      .addCase(getAllWorkspaceClients.fulfilled, (state, action) => {
        if (state.getAllWorkspaceClientsApi.status === "pending") {
          // const data = action.payload;
          const data = action.payload.map((item) => {
            return {
              value: item.client_id,
              label: `${item.name} - ${item?.client?.company_name}`,
            };
          });
          state.workspaceContacts = [...data];
          state.getAllWorkspaceClientsApi.status = "idle";
        }
      })
      .addCase(getAllWorkspaceClients.rejected, (state, action) => {
        if (state.getAllWorkspaceClientsApi.status === "pending") {
          state.getAllWorkspaceClientsApi.status = "idle";
          state.getAllWorkspaceClientsApi.error = action.error;
        }
      })
      .addCase(deleteCustomDomainFavicon.pending, (state, action) => {
        if (state.deleteCustomDomainFaviconApi.status === "idle") {
          state.deleteCustomDomainFaviconApi.status = "pending";
        }
      })
      .addCase(deleteCustomDomainFavicon.fulfilled, (state, action) => {
        if (state.deleteCustomDomainFaviconApi.status === "pending") {
          state.deleteCustomDomainFaviconApi.status = "idle";
        }
      })
      .addCase(deleteCustomDomainFavicon.rejected, (state, action) => {
        if (state.deleteCustomDomainFaviconApi.status === "pending") {
          state.deleteCustomDomainFaviconApi.status = "idle";
          state.deleteCustomDomainFaviconApi.error = action.error;
        }
      })
      .addCase(postPageNotFoundUrl.pending, (state, action) => {
        if (state.postPageNotFoundUrlApi.status === "idle") {
          state.postPageNotFoundUrlApi.status = "pending";
        }
      })
      .addCase(postPageNotFoundUrl.fulfilled, (state, action) => {
        if (state.postPageNotFoundUrlApi.status === "pending") {
          state.postPageNotFoundUrlApi.status = "idle";
        }
      })
      .addCase(postPageNotFoundUrl.rejected, (state, action) => {
        if (state.postPageNotFoundUrlApi.status === "pending") {
          state.postPageNotFoundUrlApi.status = "idle";
          state.postPageNotFoundUrlApi.error = action.error;
        }
      })
      .addCase(assignCustomDomainToClients.pending, (state, action) => {
        if (state.assignCustomDomainToClientsApi.status === "idle") {
          state.assignCustomDomainToClientsApi.status = "pending";
        }
      })
      .addCase(assignCustomDomainToClients.fulfilled, (state, action) => {
        if (state.assignCustomDomainToClientsApi.status === "pending") {
          state.assignCustomDomainToClientsApi.status = "idle";
        }
      })
      .addCase(assignCustomDomainToClients.rejected, (state, action) => {
        if (state.assignCustomDomainToClientsApi.status === "pending") {
          state.assignCustomDomainToClientsApi.status = "idle";
          state.assignCustomDomainToClientsApi.error = action.error;
        }
      })
      .addCase(saveNewCustomDomain.pending, (state, action) => {
        if (state.saveNewCustomDomainApi.status === "idle") {
          state.saveNewCustomDomainApi.status = "pending";
        }
      })
      .addCase(saveNewCustomDomain.fulfilled, (state, action) => {
        if (state.saveNewCustomDomainApi.status === "pending") {
          state.saveNewCustomDomainApi.status = "idle";
        }
      })
      .addCase(saveNewCustomDomain.rejected, (state, action) => {
        if (state.saveNewCustomDomainApi.status === "pending") {
          state.saveNewCustomDomainApi.status = "idle";
          state.saveNewCustomDomainApi.error = action.error;
        }
      })
      .addCase(assignFreePlan.pending, (state, action) => {
        if (state.assignFreePlanApi.status === "idle") {
          state.assignFreePlanApi.status = "pending";
        }
      })
      .addCase(assignFreePlan.fulfilled, (state, action) => {
        if (state.assignFreePlanApi.status === "pending") {
          state.assignFreePlanApi.status = "idle";
        }
      })
      .addCase(assignFreePlan.rejected, (state, action) => {
        if (state.assignFreePlanApi.status === "pending") {
          state.assignFreePlanApi.status = "idle";
          state.assignFreePlanApi.error = action.error;
        }
      })
      .addCase(applyCodes.pending, (state, action) => {
        if (state.applyCodesApi.status === "idle") {
          state.applyCodesApi.status = "pending";
        }
      })
      .addCase(applyCodes.fulfilled, (state, action) => {
        if (state.applyCodesApi.status === "pending") {
          state.applyCodesApi.status = "idle";
        }
      })
      .addCase(applyCodes.rejected, (state, action) => {
        if (state.applyCodesApi.status === "pending") {
          state.applyCodesApi.status = "idle";
          state.applyCodesApi.error = action.error;
        }
      })
      .addCase(cancelSubscription.pending, (state, action) => {
        if (state.cancelSubscriptionApi.status === "idle") {
          state.cancelSubscriptionApi.status = "pending";
        }
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        if (state.cancelSubscriptionApi.status === "pending") {
          state.cancelSubscriptionApi.status = "idle";
        }
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        if (state.cancelSubscriptionApi.status === "pending") {
          state.cancelSubscriptionApi.status = "idle";
          state.cancelSubscriptionApi.error = action.error;
        }
      })
      .addCase(reactivateAccount.pending, (state, action) => {
        if (state.reactivateAccountApi.status === "idle") {
          state.reactivateAccountApi.status = "pending";
        }
      })
      .addCase(reactivateAccount.fulfilled, (state, action) => {
        if (state.reactivateAccountApi.status === "pending") {
          state.reactivateAccountApi.status = "idle";
        }
      })
      .addCase(reactivateAccount.rejected, (state, action) => {
        if (state.reactivateAccountApi.status === "pending") {
          state.reactivateAccountApi.status = "idle";
          state.reactivateAccountApi.error = action.error;
        }
      });
  },
});

export const {
  setBilling,
  setLtdPlansData,
  setMrrPlansData,
  setAddOnPlansData,
  setTrailBanner,
  setCustomDomain,
  setSingleCustomDomain,
  setCustomDomainSettings,
  setOgState,
  setPayments,
  addPayment,
  updatePayment,
  resetCustomDomain,
} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
