import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  persistence: {
    admin: {
      sideNavCollapse: false,
    },
    hideDefaultLauncher: true,
    adminPath: "/",
    settingsPath: "/",
    instagram: {
      view: "kanban",
    },
  },
  user: {},
  dashboardData: {},
  totalProjects: 0
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    syncPersistence(state, action) {
      state.persistence = { ...state.persistence, ...action.payload };
    },
    updateUser(state, action) {
      // Not sure about the exact logic 
    },
    setCustomFields(state, action) {
      const { module, customFields } = action.payload;
      state[module].customFields = [...customFields];
    },
    setTotalProjects(state, action) {
      state.totalProjects = action.payload;
    },
  },
});

export const { syncPersistence, updateUser, setCustomFields, setTotalProjects } = storeSlice.actions;

export const indexReducer = storeSlice.reducer;

// also need to create action creators for async actions using Redux Thunk
