"use client";

import {
	Toast,
	ToastClose,
	ToastDescription,
	ToastProvider,
	ToastTitle,
	ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { MdErrorOutline } from "react-icons/md";
import { RiNotificationLine } from "react-icons/ri";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const toastIcons = {
	destructive: <MdErrorOutline className=" inline-block size-6" />,
	success: <IoCheckmarkCircleOutline className=" inline-block size-6" />,
	none: <RiNotificationLine className=" inline-block size-6" />,
};

export function Toaster() {
	const { toasts } = useToast();

	return (
		<ToastProvider>
			{toasts.map(function ({
				id,
				title,
				description,
				action,
				...props
			}) {
				return (
					<Toast key={id} {...props}>
						<div className="flex justify-between items-center">
							{toastIcons[props.variant || "none"]}
							{title && (
								<ToastTitle className="ml-2">
									{title}
								</ToastTitle>
							)}
							{description && (
								<ToastDescription>
									{description}
								</ToastDescription>
							)}
						</div>
						{action}
						<ToastClose />
					</Toast>
				);
			})}
			<ToastViewport />
		</ToastProvider>
	);
}
