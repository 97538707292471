import _ from "lodash";
import Api from "@/http/axiosInterceptor";
import axios from "axios";

const { createAsyncThunk } = require("@reduxjs/toolkit");

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getAllContacts = createAsyncThunk(
  "workspace/getAllContacts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/contacts/all`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getWorkspace = createAsyncThunk(
  "workspace/getWorkspace",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/workspace`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
