import { combineReducers } from "redux";
import { indexReducer } from "./Slice";
import { dashboardReducer } from "./Slice/dashboardSlice";
import { linkinBioReducer } from "./Slice/linkinBio";
import { urlRetargetingReducer } from "./Slice/urlRetargetingSlice";
import { portalReducer } from "./Slice/portalSlice";
import { settingsReducer } from "./Slice/settingsSlice";
import { workspaceReducer } from "./Slice/workspaceSlice";
import authSlice from "./Slice/authSlice";
import { adminUrlRetargetingReducer } from "./Slice/admin/adminUrlRetargeting";
import { trackingSliceReducer } from "./Slice/trackingSlice";
import { pageBuilderReducer } from "./Slice/pageBuilderSlice";
import { pageBuilderPortalReducer } from "./Slice/pageBuilderPortalSlice";
import { clientReducer } from "./Slice/clientSlice";
import {urlRetargetingPortalReducer} from "./Slice/urlRetargetingPortalSlice"
import { clientUrlRetargetingReducer } from "./Slice/client/clientUrlRetargeting";
const rootReducer = combineReducers({
  index: indexReducer,
  dashboard: dashboardReducer,
  linkinBio: linkinBioReducer,
  portal: portalReducer,
  settings: settingsReducer,
  urlRetargeting: urlRetargetingReducer,
  urlRetargetingPortal: urlRetargetingPortalReducer,
  workspace: workspaceReducer,
  auth: authSlice,
  adminUrlRetargeting: adminUrlRetargetingReducer,
  trackingSlice: trackingSliceReducer,
  pageBuilder: pageBuilderReducer,
  client: clientReducer,
  clientUrlRetargeting: clientUrlRetargetingReducer,
  pageBuilderPortal: pageBuilderPortalReducer,
  // portal: combineReducers({
  //   pageBuilder: portalPageBuilderReducer,
  // }),
});

export default rootReducer;
