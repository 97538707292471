"use client";
import localFont from "next/font/local";
import "./globals.scss";
import StoreProvider from "./StoreProvider";
import { Toaster } from "@/components/ui/toaster";
import ProtectedRoute from "@/layouts/protectedRoute";
import { NextTopLoader } from "@/lib/utils/NextTopLoader";
import "react-day-picker/dist/style.css";
import Script from "next/script";
// import NextTopLoader from "nextjs-toploader";

const nunitoSans = localFont({
  src: [
    {
      path: "../../public/fonts/NunitoSans-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/NunitoSans-Italic.ttf",
      weight: "400",
      style: "italic",
    },
    {
      path: "../../public/fonts/NunitoSans-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/NunitoSans-BoldItalic.ttf",
      weight: "700",
      style: "italic",
    },
    {
      path: "../../public/fonts/NunitoSans-Light.ttf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../public/fonts/NunitoSans-LightItalic.ttf",
      weight: "300",
      style: "italic",
    },
    {
      path: "../../public/fonts/NunitoSans-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/fonts/NunitoSans-SemiBoldItalic.ttf",
      weight: "600",
      style: "italic",
    },
  ],
});

// export const metadata = {
//   title: {
//     template: "LinkJoy | %s",
//     default: "LinkJoy",
//   },
//   description: "LinkJoy V2 release",
// };

export default function RootLayout({ children }) {
  return (
    <StoreProvider>
      <ProtectedRoute>
        <html lang="en" suppressHydrationWarning={true}>
          <head>
            <title>LinkJoy</title>
            <link rel="icon" href="/static/favicon.png" />
            <link
              rel="stylesheet"
              href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"
            />
          </head>
          <body
            className={nunitoSans.className}
            suppressHydrationWarning={true}>
            <NextTopLoader />
            <main>{children}</main>
            <Toaster />
            <Script
              type="module"
              src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"
            />
          </body>
        </html>
      </ProtectedRoute>
    </StoreProvider>
  );
}
