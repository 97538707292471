import { createSlice, current } from "@reduxjs/toolkit";
import { getClients, deleteClients, putClient } from "../Thunks/clientAction";
import {
  createClient,
  getClientContacts,
  getPrimaryClient,
} from "../Thunks/clientActions";

const initialState = {
  isLoading: false,
  isContactLoading: false,
  isPrimaryContactLoading: false,
  client: {
    clients: [],
    page_data: {},
    total_clients: 0,
    records_per_page: 0,
  },
  primaryClient: {},
  contact: {
    contactsData: [],
    pagination: {
      current: 1,
    },
  },
  pagination: {
    current: 1,
  },
  getClientsApi: {
    status: "idle",
    error: null,
  },
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state, action) => {
        if (state.getClientsApi.status === "idle") {
          state.getClientsApi.status = "pending";
        }
        state.isLoading = true;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        if (state.getClientsApi.status === "pending") {
          state.client = action.payload;
          state.pagination = action.payload?.page_data;
          state.getClientsApi.status = "idle";
        }
        state.isLoading = false;
      })
      .addCase(getClients.rejected, (state, action) => {
        if (state.getClientsApi.status === "pending") {
          state.getClientsApi.error = action.payload;
          state.getClientsApi.status = "idle";
        }
        state.isLoading = false;
      })
      .addCase(createClient.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createClient.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteClients.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteClients.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteClients.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPrimaryClient.pending, (state, action) => {
        state.isPrimaryContactLoading = true;
      })
      .addCase(getPrimaryClient.fulfilled, (state, action) => {
        state.isPrimaryContactLoading = false;
        state.primaryClient = action.payload;
      })
      .addCase(getPrimaryClient.rejected, (state, action) => {
        state.isPrimaryContactLoading = false;
      })
      .addCase(getClientContacts.pending, (state, action) => {
        state.isContactLoading = true;
      })
      .addCase(getClientContacts.fulfilled, (state, action) => {
        state.isContactLoading = false;
        state.contact.contactsData = action.payload;
      })
      .addCase(getClientContacts.rejected, (state, action) => {
        state.isContactLoading = false;
      });
    // .addCase(putClient.pending, (state, action) => {
    //   state.isLoading = true;
    // })
    // .addCase(putClient.fulfilled, (state, action) => {
    //    state.isLoading = false;
    //    state.client = action.payload;
    //    state.pagination = action.payload?.page_data;
    // })
    // .addCase(putClient.rejected, (state) => {
    //   state.isLoading = false;
    // });
  },
});

export const clientReducer = clientSlice.reducer;
