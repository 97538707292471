import { createSlice } from "@reduxjs/toolkit";
import {
  getUserInfo,
  getClientsList,
  postLogo,
  signIn,
  setPublicPortalData,
} from "@/redux/Thunks/portalAction";

const initialState = {
  clients: [],
  contactUuid: "null",
  workspaceId: "null",
  user: {},
  portaluser: {},
  workspace: {},
  isLoading: false,
  error: null,
  adminWorkSpace: {},
};

export const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    // reset: (state) => {
    //   return initialState;
    // },
    setCurrentWorkspace: (state, action) => {
      state.workspace = { ...action.payload };
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setWorkspaceId: (state, action) => {
      state.workspaceId = action.payload;
    },
    setClientsList: (state, action) => {
      state.clients = action.payload;
    },
    setContactUuid: (state, action) => {
      state.contactUuid = action.payload;
      if (action.payload === "null") {
        state.user = {};
      }
    },
    setWorkspaceLogo: (state, action) => {
      state.workspace.owner.company_logo = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getClientsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clients = action.payload;
      })
      .addCase(getClientsList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postLogo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postLogo.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postLogo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(signIn.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload?.user;
      })
      .addCase(signIn.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setPublicPortalData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(setPublicPortalData.fulfilled, (state, action) => {
        state.isLoading = false;

        state.adminWorkSpace = action.payload;
      })
      .addCase(setPublicPortalData.rejected, (state) => {
        state.isLoading = false;
      });

    // builder.addCase(getUserInfo.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getUserInfo.fulfilled, (state, action) => {

    //   state.isLoading = false;
    //   state.user = {...action.payload};
    // });
    // builder.addCase(getUserInfo.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.error;
    // })
    // builder.addCase(getClientsList.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getClientsList.fulfilled, (state, action) => {

    //   state.isLoading = false;
    //   state.clients = action.payload;
    // });
    // builder.addCase(getClientsList.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.error;
    // });
  },
});

export const {
  reset,
  setCurrentWorkspace,
  setUser,
  setClientsList,
  setContactUuid,
  setWorkspaceId,
  setWorkspaceLogo,
} = portalSlice.actions;

export const portalReducer = portalSlice.reducer;
